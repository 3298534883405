import Home from "./app/scenes/Home/Home";
import About from "./app/scenes/About/About";
import Project from "./app/scenes/Project/Project";
import Team from "./app/scenes/Team/Team";
import Pro from "./app/scenes/Pro/Pro";
import Family from "./app/scenes/Family/Family";
import Contact from "./app/scenes/Contact/Contact";
import Subscribe from "./app/scenes/Subscribe/Subscribe";

const routes = [
	{
		component: Home,
		exact: true,
		path: "/"
	},
	{
		component: About,
		path: "/about",
		exact: true
	},
	{
		component: Project,
		path: "/project",
		exact: true
	},
	{
		component: Team,
		path: "/team",
		exact: true
	},
	{
		component: Pro,
		path: "/pro",
		exact: true
	},
	{
		component: Family,
		path: "/family",
		exact: true
	},
	{
		component: Contact,
		path: "/contact",
		exact: true
	},
	{
		component: Subscribe,
		path: "/subscribe",
		exact: true
	},
	{
		component: () => "404 NOT FOUND",
		path: "*",
		exact: true
	}
];

export default routes;
