import React from "react";

const people = [
	{
		name: "",
		role: "Directrice / Educatrice de jeunes enfants",
		imageUrl: "assets/images/profile/lady1.png"
	},
	{
		name: "",
		role: "Médecin de crèche",
		imageUrl: "assets/images/profile/man4.png"
	},
	{
		name: "",
		role: "Psychologue",
		imageUrl: "assets/images/profile/man2.png"
	},
	{
		name: "",
		role: "Educatrice de jeunes enfants",
		imageUrl: "assets/images/profile/lady2.png"
	},
	{
		name: "",
		role: "Auxilliaire de puéricultures",
		imageUrl: "assets/images/profile/lady4.png"
	},
	{
		name: "",
		role: "Auxilliaire de puéricultures",
		imageUrl: "assets/images/profile/lady3.png"
	},
	{
		name: "",
		role: "Assistante petite enfance (Bep carrière sanitaire et social)",
		imageUrl: "assets/images/profile/lady5.png"
	},
	{
		name: "",
		role: "Assistante petite enfance (cap petite enfance",
		imageUrl: "assets/images/profile/lady2.png"
	},
	{
		name: "",
		role: "Assistante petite enfance (cap petite enfance",
		imageUrl: "assets/images/profile/lady4.png"
	},
	{
		name: "",
		role: "Assistante petite enfance (cap petite enfance",
		imageUrl: "assets/images/profile/lady3.png"
	}
];

function Team(props) {
	return (
		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
				<div className="space-y-8 sm:space-y-12">
					<div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Notre équipe
						</h2>
						<h3>
							<span className="mt-2 block text-xl leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Le personnel de la Crèche EfferVsens est composé de professionnelle de la petite enfance.
							</span>
						</h3>
					</div>
					<ul className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
						{people.map(person => (
							<li key={person.name}>
								<div className="space-y-4">
									<img className="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24" src={person.imageUrl} alt="" />
									<div className="space-y-2">
										<div className="text-xs font-medium lg:text-sm">
											<h3>{person.name}</h3>
											<p className="text-indigo-600">{person.role}</p>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Team;
