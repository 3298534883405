import React from "react";
import { Helmet } from "react-helmet";

export default function Home() {
	return (
		<>
			<Helmet>
				<title>Bienvenue à la crèche EfferVsens</title>
				<meta
					name="description"
					content="Nous offrons à vos enfants un lieu d'accueil chaleureux, convivial, et riche d'échanges parents/professionnelles."
				/>
			</Helmet>
			<main className="lg:relative">
				<div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
					<div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
						<h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
							<span className="block xl:inline">Bienvenue à la</span>{" "}
							<span className="block text-purple-600 xl:inline">crèche EfferVsens</span>
						</h1>
						<p className="mt-3 max-w-md mx-auto text-lg text-gray-700 sm:text-xl md:mt-5 md:max-w-3xl">
							Nous offrons à vos enfants un lieu d'accueil chaleureux, convivial, et riche d'échanges
							parents/professionnelles.
						</p>
						<div className="mt-10 sm:flex sm:justify-center lg:justify-start">
							<div className="rounded-md shadow">
								<a
									href="/about"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
								>
									Mieux nous connaitre
								</a>
							</div>
							<div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
								<a
									href="/contact"
									className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
								>
									Nous contacter
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
					<img
						className="absolute inset-0 w-full h-full object-cover"
						src="assets/images/hero/hero-bg.jpg"
						alt="Un enfant qui empile des legos"
					/>
				</div>
			</main>
		</>
	);
}
