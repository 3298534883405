import React from "react";
import { Helmet } from "react-helmet";

function About(props) {
	return (
		<>
			<Helmet>
				<title>Mieux nous connaitre - Crèche EfferVsens</title>
				<meta
					name="description"
					content="Découvrez la Crèche EfferVsens et notre mission d’offrir aux enfants un lieu d'accueil, chaleureux, convivial, et riche d'échanges parents/professionnelles."
				/>
			</Helmet>
			<div className="bg-white">
				<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
					<div className="text-center">
						<h2 className="text-base font-semibold text-pink-600 tracking-wide uppercase">EFFERVSENS</h2>
						<p className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Mieux nous connaitre.
						</p>
						<p className="max-w-2/3 mt-5 mx-auto text-md text-gray-700">
							Forte de 10 ans d’expérience dans la petite enfance Grace London fondatrice de la Crèche EfferVsens,
							propose d’offrir aux enfants un lieu d'accueil, chaleureux, convivial, et riche d'échanges
							parents/professionnelles.
						</p>
						<p className="max-w-2/3 mt-5 mx-auto text-md text-gray-700">
							La Crèche EfferVsens, accueille les enfants de 2 mois et demi à 3 ans, elle a pour mission de veiller à la
							santé, à la sécurité et au bien-être des enfants ainsi qu’à leur développement physique et psychologique.
						</p>
					</div>
				</div>
			</div>
			<div className="bg-pink-50">
				<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
					<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
						<span className="block">Vous souhaitez une place pour votre enfant?</span>
						<span className="block text-pink-600">Votre entreprise peut vous y aider.</span>
					</h2>
					<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
						<div className="inline-flex rounded-md shadow">
							<a
								href="/family"
								className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700"
							>
								En savoir plus
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
