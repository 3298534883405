import React from "react";
import NavBar from "../NavBar";

const AppLayout = props => {
	return (
		<>
			<NavBar />
			{props.children}
		</>
	);
};

export default AppLayout;
