/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import API from "backend/http-common";
import History from "../../../@history/@history";
import Message from "../../../shared-components/Message";

const defaultForm = {
	children: 1,
	family: "no",
	civil: "monsieur",
	lastname: "",
	firstname: "",
	email: "",
	address: "",
	zipCode: "",
	city: "",
	phone: "",
	salary: "",
	firstjob: "Salarié",
	secondjob: "Salarié",
	knowus: "Vous nous avez connu par...",
	accept: false
};

const children = [
	{
		value: 1,
		option: "1"
	},
	{
		value: 2,
		option: "2"
	},
	{
		value: 3,
		option: "3"
	},
	{
		value: 4,
		option: "4"
	},
	{
		value: 5,
		option: "5"
	},
	{
		value: 6,
		option: "6"
	},
	{
		value: 7,
		option: "7"
	},
	{
		value: 8,
		option: "8+"
	}
];

const status = [
	{
		value: "Apprenti",
		option: "Apprenti"
	},
	{
		value: "Artisan",
		option: "Artisan"
	},
	{
		value: "Auteur",
		option: "Auteur"
	},
	{
		value: "Commerçant",
		option: "Commerçant"
	},
	{
		value: "Exploitant",
		option: "Exploitant"
	},
	{
		value: "Agricole",
		option: "Agricole"
	},
	{
		value: "Fonctionnaire",
		option: "Fonctionnaire"
	},
	{
		value: "Intérimaire",
		option: "Intérimaire"
	},
	{
		value: "Intermittent du spectacle",
		option: "Intermittent du spectacle"
	},
	{
		value: "Libéral",
		option: "Libéral"
	},
	{
		value: "Pigiste",
		option: "Pigiste"
	},
	{
		value: "Saisonnier",
		option: "Saisonnier"
	},
	{
		value: "Salarié",
		option: "Salarié"
	},
	{
		value: "Travailleur indépendant",
		option: "Travailleur indépendant"
	},
	{
		value: "Travailleur intermittent",
		option: "Travailleur intermittent"
	}
];

const social = [
	{
		value: "Bouche à oreille/ Famille/ Amis",
		option: "Bouche à oreille/ Famille/ Amis"
	},
	{
		value: "Moteur de recherche",
		option: "Moteur de recherche"
	},
	{
		value: "Réseaux sociaux",
		option: "Réseaux sociaux"
	},
	{
		value: "Prospectus",
		option: "Prospectus"
	},
	{
		value: "Presse/ TV",
		option: "Presse/ TV"
	},
	{
		value: "La mairie",
		option: "La mairie"
	},
	{
		value: "Médecin/ Gynécolgue/ Maternité",
		option: "Médecin/ Gynécolgue/ Maternité"
	},
	{
		value: "Une crèche",
		option: "Une crèche"
	},
	{
		value: "Autre",
		option: "Autre"
	}
];

export default function Subscribe() {
	const [form, setForm] = useState(defaultForm);
	const [success, setSuccess] = useState(false);
	const [warning, setWarning] = useState(false);
	const subject = `${form.lastname} ${form.firstname} souhaite s'inscrire à la crèche EfferVsens`;

	const handleChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		API.post("/subscribe", {
			...form,
			from: form.email,
			subject
		})
			.then(res => {
				if (res.status === 200) {
					setSuccess(true);
					showMessage("Votre message a été envoyé", "success").then(() => {
						History.push("/");
					});
				}
			})
			.catch(error => {
				if (!error.response) {
					// network error
					error.errorStatus = "Error: Network Error";
				} else {
					error.errorStatus = error.response.data.message;
				}
				return <Message options={{ message: error.errorStatus, variant: "error" }} state />;
			});
	};

	const showMessage = (message, variant) => (
		<Message
			options={{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center"
				},
				autoHideDuration: 3000,
				message,
				variant
			}}
			state
		/>
	);

	return (
		<div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
			{success ? (
				<Message
					options={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center"
						},
						autoHideDuration: 3000,
						message: "Votre message a bien été envoyé",
						variant: "success"
					}}
					state
				/>
			) : warning ? (
				<Message
					options={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center"
						},
						autoHideDuration: 3000,
						message: "Merci de bien remplir les champs obligatoires",
						variant: "warning"
					}}
					state
				/>
			) : null}
			<div className="relative max-w-xl mx-auto">
				<svg
					className="absolute left-full transform translate-x-1/2"
					width={404}
					height={404}
					fill="none"
					viewBox="0 0 404 404"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="85737c0e-0916-41d7-917f-596dc7edfa27"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} className="text-pink-100" fill="currentColor" />
						</pattern>
					</defs>
					<rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
				</svg>
				<svg
					className="absolute right-full bottom-0 transform -translate-x-1/2"
					width={404}
					height={404}
					fill="none"
					viewBox="0 0 404 404"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="85737c0e-0916-41d7-917f-596dc7edfa27"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
						</pattern>
					</defs>
					<rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
				</svg>
				<div className="text-center">
					<h2 className="text-3xl font-extrabold tracking-tight text-purple-500 sm:text-4xl">Inscription</h2>
					<h4>
						<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
							Inscrivez-vous en 3 minutes, nos experts s'occupent du reste!
						</span>
					</h4>
				</div>
				<div className="mt-12">
					<form onSubmit={handleSubmit} noValidate className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
						<div className="sm:col-span-2">
							<h6>
								<span className="mt-2 block text-left leading-8 tracking-tight text-indigo-400 font-bold">
									{`${form.children > 1 ? "Enfants" : "Enfant"} à accueillir`}
								</span>
							</h6>
							<label htmlFor="children" className="block text-sm font-medium text-gray-700">
								Nombre d'enfants à accueillir en crèche
							</label>
							<select
								id="children"
								name="children"
								required
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.children}
								onChange={handleChange}
							>
								{children.map(child => (
									<option key={child.value} value={child.value}>
										{child.option}
									</option>
								))}
							</select>
						</div>
						<div className="sm:col-span-2">
							<h6>
								<span className="mt-2 block text-left leading-8 tracking-tight text-indigo-400 font-bold">
									Informations personnelles
								</span>
							</h6>
							<label htmlFor="family" className="block text-sm font-medium text-gray-700">
								Famille monoparentale
							</label>
							<select
								id="family"
								name="family"
								disabled
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.family}
								onChange={handleChange}
							>
								<option value="no">Non</option>
								<option value="yes">Oui</option>
							</select>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="civil" className="block text-sm font-medium text-gray-700">
								Civilité
							</label>
							<select
								id="civil"
								name="civil"
								required
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.civil}
								onChange={handleChange}
							>
								<option value="monsieur">Monsieur</option>
								<option value="madame">Madame</option>
								<option value="autre">Autre</option>
							</select>
						</div>
						<div>
							<label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
								Nom
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="lastname"
									id="lastname"
									value={form.lastname}
									onChange={handleChange}
									autoComplete="family-name"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div>
							<label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
								Prénom
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="firstname"
									id="firstname"
									value={form.firstname}
									onChange={handleChange}
									autoComplete="given-name"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="email" className="block text-sm font-medium text-gray-700">
								Email
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									value={form.email}
									onChange={handleChange}
									autoComplete="email"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="address" className="block text-sm font-medium text-gray-700">
								Adresse
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="address"
									id="address"
									required
									value={form.address}
									onChange={handleChange}
									autoComplete="organization"
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>

						<div>
							<label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
								Code Postal
							</label>
							<div className="mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="zipCode"
									id="zipCode"
									required
									value={form.zipCode}
									onChange={handleChange}
									autoComplete="tel"
									className="py-3 px-4 block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div>
							<label htmlFor="city" className="block text-sm font-medium text-gray-700">
								Ville
							</label>
							<div className="mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="city"
									id="city"
									required
									value={form.city}
									onChange={handleChange}
									autoComplete="tel"
									className="py-3 px-4 block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="phone" className="block text-sm font-medium text-gray-700">
								Téléphone
							</label>
							<div className="mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="phone"
									id="phone"
									required
									value={form.phone}
									onChange={handleChange}
									autoComplete="tel"
									className="py-3 px-4 block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="salary" className="block text-sm font-medium text-gray-700">
								Revenus nets mensuels du foyer
							</label>
							<div className="mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="salary"
									id="salary"
									required
									value={form.salary}
									onChange={handleChange}
									autoComplete="tel"
									className="py-3 px-4 block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<h6>
								<span className="mt-2 block text-left leading-8 tracking-tight text-indigo-400 font-bold">
									Informations professionnelles
								</span>
							</h6>
							<label htmlFor="firstjob" className="block text-sm font-medium text-gray-700">
								Votre statut professionel
							</label>
							<select
								id="firstjob"
								name="firstjob"
								required
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.firstjob}
								onChange={handleChange}
							>
								{status.map(stat => (
									<option key={stat.value} value={stat.value}>
										{stat.option}
									</option>
								))}
							</select>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="secondjob" className="block text-sm font-medium text-gray-700">
								Le statut professionnel de votre conjoint(e)
							</label>
							<select
								id="secondjob"
								name="secondjob"
								required
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.secondjob}
								onChange={handleChange}
							>
								{status.map(stat => (
									<option key={stat.value} value={stat.value}>
										{stat.option}
									</option>
								))}
							</select>
						</div>
						<div className="sm:col-span-2">
							<h6>
								<span className="mt-2 block text-left leading-8 tracking-tight text-indigo-400 font-bold">
									Questions complémentaires
								</span>
							</h6>
							<label htmlFor="knowus" className="block text-sm font-medium text-gray-700">
								Comment avez-vous connu EfferVsens?
							</label>
							<select
								id="knowus"
								name="knowus"
								required
								className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
								value={form.knowus}
								onChange={handleChange}
							>
								{social.map(soc => (
									<option key={soc.value} value={soc.value}>
										{soc.option}
									</option>
								))}
							</select>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="message" className="block text-sm font-medium text-gray-700">
								Des précisions concernants votre inscription?
							</label>
							<div className="mt-1">
								<textarea
									id="message"
									name="message"
									value={form.message}
									onChange={handleChange}
									rows={4}
									placeholder="Votre message..."
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="relative flex items-start sm:col-span-2">
							<div className="flex items-start">
								<input
									id="accept"
									aria-describedby="accept-rules"
									name="accept"
									type="checkbox"
									required
									checked={form.accept}
									onChange={() => setForm({ ...form, accept: !form.accept })}
									className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-700 rounded"
								/>
							</div>
							<div className="ml-3 text-sm">
								<p id="comments-description" className="text-gray-700">
									En cochant, je valide et comprends qu'il m'est possible de revenir sur ma décision et qu'il est de mon
									droit de demander suppression de toute information me concernant.
								</p>
							</div>
						</div>
						<div className="sm:col-span-2">
							<input
								type="submit"
								value="S'inscrire"
								className="w-full cursor-pointer inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
