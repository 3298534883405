import { amber, blue, green, red } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
	root: {},
	success: {
		backgroundColor: green[600],
		color: "#FFFFFF"
	},
	error: {
		backgroundColor: red[600],
		color: "#FFFFFF"
	},
	info: {
		backgroundColor: blue[600],
		color: "#FFFFFF"
	},
	warning: {
		backgroundColor: amber[600],
		color: "#FFFFFF"
	}
}));

const initialState = {
	state: null,
	options: {
		anchorOrigin: {
			vertical: "top",
			horizontal: "center"
		},
		autoHideDuration: 6000,
		message: "Hi",
		variant: "info"
	}
};

const Message = props => {
	const [message, setMessage] = useState(initialState);
	const { options, state } = props;
	useEffect(() => {
		setMessage({ ...message, options, state });
	}, [setMessage]);
	const classes = useStyles();

	const hideMessage = () => {
		return setMessage({ ...message, state: null });
	};

	return (
		<Snackbar
			{...options}
			open={message.state}
			onClose={hideMessage}
			classes={{
				root: classes.root
			}}
			ContentProps={{
				variant: "body2",
				headlineMapping: {
					body1: "div",
					body2: "div"
				}
			}}
		>
			<SnackbarContent
				className={clsx(classes[message.options.variant])}
				message={
					<div className="flex items-center">
						<Typography className="mx-8">{message.options.message}</Typography>
					</div>
				}
			/>
		</Snackbar>
	);
};

export default React.memo(Message);
