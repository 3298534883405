import React from "react";

function Project(props) {
	return (
		<>
			<div className="relative py-16 bg-white overflow-hidden">
				<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
					<div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
						<svg
							className="absolute top-12 left-full transform translate-x-32"
							width={404}
							height={384}
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
						</svg>
						<svg
							className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
							width={404}
							height={384}
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="f210dbf6-a58d-4871-961e-36d5016a0f49"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
						</svg>
						<svg
							className="absolute bottom-12 left-full transform translate-x-32"
							width={404}
							height={384}
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="d3eb07ae-5182-43e6-857d-35c643af9034"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
						</svg>
					</div>
				</div>
				<div className="relative px-4 sm:px-6 lg:px-8">
					<div className="text-lg max-w-prose mx-auto">
						<h1>
							<span className="block text-base text-center text-pink-600 font-semibold tracking-wide uppercase">
								Notre Projet
							</span>
						</h1>
						<h2>
							<span className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
								Nos valeurs
							</span>
						</h2>
						<div className="mt-6 prose prose-indigo prose-md text-gray-600 mx-auto">
							<ul>
								<li>
									<span className="text-purple-400 font-semibold">E</span>panouissement de l'enfant
								</li>
								<li>
									<span className="text-purple-400 font-semibold">F</span>avorisation de l'estime de soi
								</li>
								<li>
									<span className="text-purple-400 font-semibold">F</span>avorisation de l'autonomie
								</li>
								<li>
									<span className="text-purple-400 font-semibold">E</span>coute attentive
								</li>
								<li>
									<span className="text-purple-400 font-semibold">R</span>espect des origines
								</li>
								<li>
									<span className="text-purple-400 font-semibold">V</span>alorisation des compétences
								</li>
								<li>
									<span className="text-purple-400 font-semibold">S</span>ensibilisation à l'environnement
								</li>
								<li>
									<span className="text-purple-400 font-semibold">E</span>veil des sens
								</li>
								<li>
									<span className="text-purple-400 font-semibold">N</span>égociations
								</li>
								<li>
									<span className="text-purple-400 font-semibold">S</span>ocialisation de l'enfant
								</li>
							</ul>
						</div>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<h2>
							<span className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
								Notre pédagogie
							</span>
						</h2>
						<figure>
							<img
								className="w-full rounded-lg"
								src="assets/images/content/pedagogie.jpg"
								alt="Notre pédagogie - enfants qui rangent des crayons dans une boite"
								width={1310}
								height={873}
							/>
							<figcaption>
								Photo by{" "}
								<a href="https://unsplash.com/@tinafloersch?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Tina Floersch
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/s/photos/children?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Unsplash
								</a>
							</figcaption>
						</figure>
						<p>
							Le <span className="text-blue-500 font-semibold">respect de l’enfant</span> dans sa personne entière (de
							son individualité, rythme de vie, sommeil, alimentation)…
						</p>
						<ul>
							<li>
								Le <span className="text-orange-500 font-semibold">libre choix</span>, dans un cadre sécurisant l’enfant
								va explorer l’espace selon ses envies, et ses capacités. L’équipe est dans la proposition et non dans
								l’obligation.
							</li>
							<li>
								L'<span className="text-green-500 font-semibold">ambiance</span> nous avons à cœur d’accueillir les
								enfants dans un espace de vie agréable et serein.
							</li>
							<li>
								L' <span className="text-purple-400 font-semibold">accompagnement</span> dans l’acquisition de son
								autonomie: « apprend moi à faire seul »
							</li>
						</ul>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-600 mx-auto">
						<h2>
							<span className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
								Nos activités
							</span>
						</h2>
						<h3>
							<span className="mt-2 block text-xl leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Dans la section des bébés/ moyens
							</span>
						</h3>
						<p>
							Les premières découvertes des tous petits passent par l’exploration physique (motrice et sensorielle).
						</p>

						<figure>
							<img
								className="w-full rounded-lg"
								src="assets/images/content/baby-activity.jpg"
								alt="Notre pédagogie - enfants qui rangent des crayons dans une boite"
								width={1310}
								height={873}
							/>
							<figcaption>
								Photo by{" "}
								<a href="https://unsplash.com/@taiscaptures?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Tai's Captures
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/s/photos/baby-toy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Unsplash
								</a>
							</figcaption>
						</figure>
						<ul>
							<li className="pb-8">
								<span className="text-blue-600 font-bold">L'éveil sensoriel</span> : Tout au long de l’année, et surtout
								les premiers mois d’accueil, l’équipe propose aux enfants de petits jouets légers faciles à toucher et à
								attraper pour favoriser le développement de leurs sens : boite à musique, hochets, mobiles, couvercles,
								livres (de différentes matières)… L’évolution des jouets est régulièrement réfléchie afin de s’adapter
								aux nouvelles acquisitions sensorielles et motrices des enfants.
							</li>
							<li className="pb-8">
								<span className="text-orange-600 font-bold">L'éveil psychomoteur</span> : Lorsque les enfants se
								déplacent en rampant, les lieux de vie sont réaménagés en fonction de leurs capacités motrices et de
								leurs besoins de progression physique. Quelques petits blocs moteurs bas sont installés afin de les
								inciter à réaliser de nouvelles expériences motrices.
							</li>
						</ul>
						<h3>
							<span className="mt-2 block text-xl leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Dans la section des moyens/ grands
							</span>
						</h3>
						<p>
							Les enfants jouent tout au long de la journée. Ils ont à leur disposition différents jouets et espaces de
							jeux :
						</p>

						<figure>
							<img
								className="w-full rounded-lg"
								src="assets/images/content/medium-toys.jpg"
								alt="Notre pédagogie - enfants qui rangent des crayons dans une boite"
								width={1310}
								height={873}
							/>
							<figcaption>
								Photo by{" "}
								<a href="https://unsplash.com/@prochurchmedia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Pro Church Media
								</a>{" "}
								on{" "}
								<a href="https://unsplash.com/s/photos/kids-toy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
									Unsplash
								</a>
							</figcaption>
						</figure>
						<ul>
							<li className="pb-8">
								<span className="text-green-600 font-bold">Jeux spontanés</span> : différents espaces sont aménagés avec
								un mobilier adapté à l’enfant afin qu’il évolue librement dans la section : un espace dînette, un espace
								poupées, un espace déguisement, un espace jeux d’encastrements (puzzles, abaques…), un espace moteur, un
								espace bricolage, un espace animaux de la ferme, un espace livres, etc. Ces différents espaces
								favorisent chez l’enfant l’imitation, les jeux symboliques, le développement du schéma corporel et la
								motricité.
							</li>
							<li className="pb-8">
								<span className="text-purple-600 font-bold">Jeux "dirigés"</span> : L’équipe propose aux enfants de
								venir expérimenter des sensations nouvelles (toucher, ouïe, motrice..) lors d’ateliers (La peinture, les
								jeux d’eau, le sable, la danse, la musique avec les instruments, la pâte à modeler, les gommettes, les
								encastrements et les puzzles, etc.) Le professionnel accompagne l’enfant pendant toute l’activité, qui
								se déroule en petit groupe.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export default Project;
