/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import API from "backend/http-common";
import Iframe from "react-iframe";
import History from "@history";
import Message from "shared-components/Message";
import clsx from "clsx";
import Joi from "joi";
import DOMPurify from "dompurify";

const defaultForm = {
	firstname: "",
	lastname: "",
	company: "",
	email: "",
	phone: "",
	message: ""
};

export default function Contact() {
	const [success, setSuccess] = useState(false);
	const [warning, setWarning] = useState(false);
	const [form, setForm] = useState(defaultForm);
	const subject = `${form.firstname} ${form.lastname} ${form.company && `de l'entreprise ${form.company} `}`;

	const handleChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	const validToSubmit = () => {
		const schema = Joi.object({
			firstname: Joi.string().required(),
			lastname: Joi.string().required(),
			company: Joi.string(),
			email: Joi.string()
				.email({ tlds: { allow: false } })
				.required(),
			phone: Joi.string().min(10).max(15).required(),
			message: Joi.string().min(3).required()
		});

		const { error } = schema.validate(form);

		if (error) {
			showMessage("Merci de bien remplir les champs obligatoires", "warning");
			return false;
		}

		return true;
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (validToSubmit()) {
			const cleanForm = {
				...form,
				firstname: DOMPurify.sanitize(form.firstname),
				lastname: DOMPurify.sanitize(form.lastname),
				company: DOMPurify.sanitize(form.company),
				email: DOMPurify.sanitize(form.email),
				phone: DOMPurify.sanitize(form.phone),
				message: DOMPurify.sanitize(form.message)
			};
			API.post("/contact", {
				...cleanForm,
				from: cleanForm.email,
				subject
			})
				.then(res => {
					if (res.status === 200) {
						setSuccess(true);
						showMessage("Votre message a été envoyé", "success").then(() => {
							History.push("/");
						});
					}
				})
				.catch(error => {
					if (!error.response) {
						// network error
						error.errorStatus = "Error: Network Error";
					} else {
						error.errorStatus = error.response.data.message;
					}
					return <Message options={{ message: error.errorStatus, variant: "error" }} state />;
				});
		}
	};

	const showMessage = (message, variant) => (
		<Message
			options={{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center"
				},
				autoHideDuration: 3000,
				message,
				variant
			}}
			state
		/>
	);

	return (
		<div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
			{success ? (
				<Message
					options={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center"
						},
						autoHideDuration: 3000,
						message: "Votre message a bien été envoyé",
						variant: "success"
					}}
					state
				/>
			) : warning ? (
				<Message
					options={{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center"
						},
						autoHideDuration: 3000,
						message: "Merci de bien remplir les champs obligatoires",
						variant: "warning"
					}}
					state
				/>
			) : null}
			<div className="relative max-w-xl mx-auto">
				<svg
					className="absolute left-full transform translate-x-1/2"
					width={404}
					height={404}
					fill="none"
					viewBox="0 0 404 404"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="85737c0e-0916-41d7-917f-596dc7edfa27"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} className="text-pink-100" fill="currentColor" />
						</pattern>
					</defs>
					<rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
				</svg>
				<svg
					className="absolute right-full bottom-0 transform -translate-x-1/2"
					width={404}
					height={404}
					fill="none"
					viewBox="0 0 404 404"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="85737c0e-0916-41d7-917f-596dc7edfa27"
							x={0}
							y={0}
							width={20}
							height={20}
							patternUnits="userSpaceOnUse"
						>
							<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
						</pattern>
					</defs>
					<rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
				</svg>
				<div className="text-center">
					<h2 className="text-3xl font-extrabold tracking-tight text-purple-500 sm:text-4xl">Contact</h2>
				</div>
				<div className="mt-12">
					<form onSubmit={handleSubmit} noValidate className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
						<div>
							<label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
								Prénom <span className="text-red-500">*</span>
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="firstname"
									id="firstname"
									value={form.firstname}
									onChange={handleChange}
									autoComplete="given-name"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div>
							<label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
								Nom <span className="text-red-500">*</span>
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="lastname"
									id="lastname"
									value={form.lastname}
									onChange={handleChange}
									autoComplete="family-name"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="company" className="block text-sm font-medium text-gray-700">
								Entreprise
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="company"
									id="company"
									value={form.company}
									onChange={handleChange}
									autoComplete="organization"
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="email" className="block text-sm font-medium text-gray-700">
								Email <span className="text-red-500">*</span>
							</label>
							<div className="mt-1">
								<input
									id="email"
									name="email"
									type="email"
									value={form.email}
									onChange={handleChange}
									autoComplete="email"
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="phone" className="block text-sm font-medium text-gray-700">
								Numéro de téléphone <span className="text-red-500">*</span>
							</label>
							<div className="mt-1 relative rounded-md shadow-sm">
								<input
									type="text"
									name="phone"
									id="phone"
									value={form.phone}
									onChange={handleChange}
									required
									autoComplete="tel"
									className="py-3 px-4 block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<label htmlFor="message" className="block text-sm font-medium text-gray-700">
								Message <span className="text-red-500">*</span>
							</label>
							<div className="mt-1">
								<textarea
									id="message"
									name="message"
									value={form.message}
									onChange={handleChange}
									rows={4}
									placeholder="Votre message..."
									required
									className="py-3 px-4 block w-full shadow-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
								/>
							</div>
						</div>
						<div className="sm:col-span-2">
							<button
								type="submit"
								disabled={!validToSubmit()}
								className={clsx(
									"w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium",
									validToSubmit()
										? "text-white bg-purple-500 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
										: "text-gray-100 bg-gray-500 focus:outline-none"
								)}
							>
								Envoyer
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className="relative bg-white mt-24">
				<div className="absolute inset-0">
					<div className="absolute inset-y-0 left-0 w-1/2" />
				</div>
				<div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
					<div className=" py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
						<div className="max-w-lg mx-auto">
							<h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Infos Utiles</h2>
							<p className="mt-3 text-lg leading-6 text-gray-500">Horaires d'ouverture de 07h00 à 19h00</p>
							<dl className="mt-8 text-base text-gray-500">
								<div>
									<dt className="sr-only">Adresse Postale</dt>
									<dd>
										<p>Les Temps durables</p>
										<p>22-24 rue Saint John de Perse</p>
										<p>94450, Limeil-Brévannes</p>
									</dd>
								</div>
								<div className="mt-6">
									<dt className="sr-only">Numéro de téléphone</dt>
									<dd className="flex">
										<PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
										<span className="ml-3">06.26.77.60.75</span>
									</dd>
								</div>
								<div className="mt-3">
									<dt className="sr-only">Email</dt>
									<dd className="flex">
										<MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
										<span className="ml-3">contact@crecheeffervsens.fr</span>
									</dd>
								</div>
							</dl>
							{/*
								<p className="mt-6 text-base text-gray-500">
									Looking for careers?{" "}
									<Link to="/contact" className="font-medium text-gray-700 underline">
										View all job openings
									</Link>
									.
								</p>
							*/}
						</div>
					</div>
					<div className="bg-white lg:col-span-3">
						<div className="max-w-lg lg:max-w-none">
							<Iframe
								className="border-0"
								url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.5931036710576!2d2.4708140158261624!3d48.751468979277035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60b15226d46a3%3A0x9fe995099e1c225b!2sCr%C3%A8che%20EfferVsens!5e0!3m2!1sfr!2sfr!4v1627489824439!5m2!1sfr!2sfr"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.5931036710576!2d2.4708140158261624!3d48.751468979277035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60b15226d46a3%3A0x9fe995099e1c225b!2sCr%C3%A8che%20EfferVsens!5e0!3m2!1sfr!2sfr!4v1627489824439!5m2!1sfr!2sfr"
								width="100%"
								height="600"
								allowFullScreen={false}
								loading="lazy"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
