import React from "react";
import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from "@heroicons/react/outline";

const features = [
	{
		name: "Disponibilité",
		description: "Disponibilité accrue des salariés, entrainant une réduction de l'absentéïsme.",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
			</svg>
		)
	},
	{
		name: "Valorisation",
		description: "Valorisation de l'image de l'entreprise et de son positionnement social.",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
				/>
			</svg>
		)
	},
	{
		name: "Encouragement",
		description: "Encouragement à la reprise d'activité après un congé maternité.",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
				/>
			</svg>
		)
	},
	{
		name: "Fidélisation",
		description: "Fidélisation du personnel et facilité de recrutement.",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
				/>
			</svg>
		)
	}
];

const employeeFeatures = [
	{
		name: "Flexibilité",
		description: "Flexibilité des horaires, plus adapté à la vie en entreprise (crénaux de 12h).",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		)
	},
	{
		name: "Proximité",
		description: "Proximité de la crèche, par rapport à son domicile ou son lieu de travail.",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
				/>
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
			</svg>
		)
	},
	{
		name: "Attractivité",
		description:
			"Attractivité du coût, similaire à la tarification d'une crèche municipale (Application d'un barême CAF).",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		)
	},
	{
		name: "Qualité",
		description:
			"Qualité du service d'accueil, effectué par des professionnelles compétentes et qualifiées (soumises à la même règlementation que les crèches municipales).",
		icon: () => (
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
				/>
			</svg>
		)
	}
];

function Pro(props) {
	return (
		<>
			<section className="px-2 py-6">
				<div className="overflow-hidden">
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-6 text-center">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Pourquoi la crèche pour vos salariés?
						</h2>
						<p className="max-w-2/3 mt-5 mx-auto text-md text-gray-700">
							La Crèche EfferVsens est une crèche inter-entreprises qui propose aux salariés des places en crèches pour
							leurs enfants, afin qu'ils puissent concilier harmonieusement vie professionnelle et vie familiale.
						</p>
					</div>
					<div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
						<svg
							className="hidden md:flex absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
							width={404}
							height={784}
							fill="none"
							viewBox="0 0 404 784"
							aria-hidden="true"
						>
							<defs>
								<pattern
									id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
						</svg>
						<div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
							<div className="lg:col-span-1">
								<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
									Avantages pour l'entreprise.
								</h2>
								<h3>
									<span className="mt-2 block text-xl leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
										Dynamisation de la politique sociale de votre entreprise
									</span>
								</h3>
							</div>
							<dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
								{features.map(feature => (
									<div key={feature.name}>
										<dt>
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-pink-500 text-white">
												{feature.icon()}
											</div>
											<p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
										</dt>
										<dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
									</div>
								))}
							</dl>
						</div>
					</div>
				</div>
			</section>
			<section className="px-2 py-6 bg-pink-50">
				<div className="overflow-hidden">
					<div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
						<svg
							className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
							width={404}
							height={784}
							fill="none"
							viewBox="0 0 404 784"
							aria-hidden="true"
						>
							<defs>
								<pattern
									id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
						</svg>
						<div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
							<div className="lg:col-span-1">
								<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
									Avantages pour le salarié.
								</h2>
								<h3>
									<span className="mt-2 block text-xl leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
										Mieux concilier la vie professionnelle et la vie de famille
									</span>
								</h3>
							</div>
							<dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
								{employeeFeatures.map(employeeFeature => (
									<div key={employeeFeature.name}>
										<dt>
											<div className="flex items-center justify-center h-12 w-12 rounded-md bg-pink-500 text-white">
												{employeeFeature.icon()}
											</div>
											<p className="mt-5 text-lg leading-6 font-medium text-gray-900">{employeeFeature.name}</p>
										</dt>
										<dd className="mt-2 text-base text-gray-500">{employeeFeature.description}</dd>
									</div>
								))}
							</dl>
						</div>
					</div>
				</div>
			</section>
			<section className="px-2 py-6">
				<div className="overflow-hidden">
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-6 text-center">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Le financement d'un berceau
						</h2>
						<h3>
							<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Entreprises ou Collectivités
							</span>
						</h3>
						<p>
							Largement soutenue par l’Etat, la réservation d’un berceau est très avantageuse pour votre entreprise !
						</p>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<h6 className="mt-2 block text-lg text-center leading-8 tracking-tight text-blue-400 font-bold sm:text-xl">
							BENEFICIEZ D'UNE DEFISCALISATION DE 83,3%
						</h6>
						<p>Entre Crédit d’impôt famille et déduction de charge vous bénéficiez d’importante réductions fiscales.</p>
						<ul>
							<li>
								<span className="text-green-500 font-semibold">CIF (Crédit impôt famille) de 50%</span> : Le crédit
								d'impôt s'impute sur l'impôt sur le revenu (IR) ou sur l'impôt sur les sociétés (IS) dû au titre de
								l'année au cours de laquelle l'entreprise a engagé les dépenses.
							</li>
							<li>
								<span className="text-orange-500 font-semibold">Exonération fiscale jusqu’à 33%</span> : les dépenses
								effectués pour la réservation de berceaux étant inscrite en charge dans votre compte d’exploitation
								(dépense à caractère social), votre IS ou IR se verra diminué de 33%.
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="px-2 py-6 bg-pink-700">
				<div>
					<h6 className="mt-2 block text-lg text-center leading-8 tracking-tight text-white font-bold sm:text-xl">
						Défiscalisation
					</h6>
				</div>
			</section>
			<section className="px-2 py-6">
				<div className="bg-white">
					<div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
						<h2 className="text-3xl font-extrabold text-pink-400 sm:text-4xl">
							<span className="block">
								Possibilité d’un <span className="text-pink-500">tarif dégressif</span> en fonction du nombre de
								berceaux réservés.
							</span>
						</h2>
						<p className="mt-4 text-md leading-6 text-gray-800">
							<span className="text-blue-600">
								Vous êtes une association non imposable, une collectivité, une entreprise, un artisan… et vous ne
								bénéficiez pas du CIF
							</span>
							, nous vous réservons une tarification particulière avec une aide de la CAF (par le biais du contrat
							enfance famille).
						</p>
						<a
							href="/contact"
							className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-500 hover:bg-purple-700 sm:w-auto"
						>
							En savoir plus
						</a>
					</div>
				</div>
			</section>
		</>
	);
}

export default Pro;
