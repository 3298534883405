import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const navigation = [
	{ name: "A propos", href: "/about" },
	{ name: "Notre Projet", href: "/project" },
	{ name: "Notre équipe", href: "/team" },
	{ name: "Entreprises ou Collectivité", href: "/pro" },
	{ name: "Famille", href: "/family" },
	{ name: "Contact", href: "/contact" }
];

const NavBar = () => {
	return (
		<Popover>
			{({ open }) => (
				<>
					<div className="max-w-7xl mx-auto px-4 sm:px-6">
						<nav
							className="relative flex items-center justify-between sm:h-10 md:justify-center my-4"
							aria-label="Global"
						>
							<div className="flex items-center flex-1 lg:absolute lg:inset-y-0 lg:left-0">
								<div className="flex items-center justify-between w-full lg:w-auto">
									<Link to="/">
										<span className="sr-only">EfferVsens</span>
										<img className="h-8 w-auto sm:h-8" src="assets/images/logos/logonav.png" alt="Logo EfferVsens" />
									</Link>
									<div className="-mr-2 flex items-center lg:hidden">
										<Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
											<span className="sr-only">Ouvrir le menu principal</span>
											<MenuIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
							</div>
							<div className="hidden lg:flex lg:space-x-10">
								{navigation.map(item => (
									<Link key={item.name} to={item.href} className="font-medium text-gray-500 hover:text-gray-900">
										{item.name}
									</Link>
								))}
							</div>
							<div className="hidden lg:absolute lg:flex lg:items-center lg:justify-end lg:inset-y-0 lg:right-0">
								<span className="inline-flex rounded-md shadow">
									<Link
										to="/subscribe"
										className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-gray-50"
									>
										Inscription
									</Link>
								</span>
							</div>
						</nav>
					</div>

					<Transition
						show={open}
						as={Fragment}
						enter="duration-150 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Popover.Panel
							focus
							static
							className="z-50 absolute top-0 inset-x-0 transition transform origin-top-right lg:hidden"
						>
							<div className="rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden px-5">
								<div className=" pt-4 flex items-center justify-between">
									<div>
										<img className="h-8 w-auto" src="assets/images/logos/logo.png" alt="Logo de la crèche efferVsens" />
									</div>
									<div className="-mr-2">
										<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
											<span className="sr-only">Close menu</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</Popover.Button>
									</div>
								</div>
								<div className="pt-2 pb-3">
									{navigation.map(item => (
										<Link
											key={item.name}
											to={item.href}
											className="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
										>
											{item.name}
										</Link>
									))}
								</div>
								<Link
									to="/subscribe"
									className="block w-full mb-5 px-5 py-3 text-center font-medium border-2 border-purple-600 text-purple-600 bg-gray-50 hover:bg-purple-100"
								>
									Inscription
								</Link>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default NavBar;
