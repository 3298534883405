import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt from "jsonwebtoken";
import Auth from "./Auth";

export const SimpleRoute = comp => {
	return <Route key={comp.path} path={comp.path} exact={comp.exact} component={comp.component} />;
};

export const ProtectedRoutes = ({ component: Component, ...rest }) => {
	const token = localStorage.getItem("token");

	if (!token) {
		return <Redirect to="/" />;
	}

	try {
		const decoded = jwt.verify(token, process.env.JWT_SECRET);
		if (decoded.exp < Date.now() / 1000) {
			localStorage.removeItem("token");
			return <Redirect to="/" />;
		}
	} catch (err) {
		localStorage.removeItem("token");
		return <Redirect to="/" />;
	}

	return <Route {...rest} render={props => <Component {...props} />} />;
};
