import React from "react";

const children = [
	{
		children: 1,
		percent: "0,06"
	},
	{
		children: 2,
		percent: "0,05"
	},
	{
		children: 3,
		percent: "0,04"
	},
	{
		children: 4,
		percent: "0,03"
	}
];

function Family(props) {
	return (
		<div>
			<section className="px-2 py-6">
				<div className="overflow-hidden">
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-6 text-center">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							L'accueil de votre enfant
						</h2>
						<h3>
							<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Afin de respecter les besoins et le rythme de chaque enfant, la crèche accueil les enfants dans deux
								sections:
							</span>
						</h3>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<svg
							className="hidden md:flex absolute top-0 right-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:translate-x-2/3 lg:translate-y-1/4"
							width={404}
							height={784}
							fill="none"
							viewBox="0 0 404 784"
							aria-hidden="true"
						>
							<defs>
								<pattern
									id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
						</svg>
						<div className="text-center">
							<h6 className="mt-2 block text-lg leading-8 tracking-tight text-blue-700 font-bold sm:text-2xl">
								Les bébés/ moyens
							</h6>
							<small>Places disponibles: 10 bébés de 3-6 mois à 18 mois.</small>
						</div>
						<p>Cette section est aménagée en trois parties:</p>
						<ul>
							<li>
								Un espace d'<span className="text-blue-300 font-semibold">éveil</span> pour les nourissons.
							</li>
							<li>
								Un espace <span className="text-blue-300 font-semibold">moteur</span> pour les plus grands.
							</li>
							<li>
								Un espace <span className="text-blue-300 font-semibold">repas</span> pour les biberons et les repas
								mixés des plus grands.
							</li>
						</ul>
					</div>
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-3xl mb-6 text-center">
						<p>
							La chambre des bébés/moyens comprend 10 berceaux respectant les normes de sécurité. La journée est
							organisée en respectant le rythme et les habitudes de chaque bébé tant au niveau du repas, que des temps
							de veille et des temps de sommeil.
						</p>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<div className="text-center">
							<h6 className="mt-2 block text-lg leading-8 tracking-tight text-blue-700 font-bold sm:text-2xl">
								Les moyens/ grands
							</h6>
							<small>Places disponibles: 15 enfants de 19 mois à 3 ans.</small>
						</div>
						<p>Cette section est aménagée en quatre parties:</p>
						<ul>
							<li>
								Un espace comprenant une <span className="text-red-400 font-semibold">structure motrice</span>.
							</li>
							<li>
								Un espace comprenant du <span className="text-red-400 font-semibold">mobilier de jeu symbolique</span> .
							</li>
							<li>
								Un espace <span className="text-red-400 font-semibold">d'activité manuelle</span> (
								<span className="text-blue-300 font-semibold">chaise, tables...</span>), cette espace sera aussi utilisé
								pour le repas des moyens et des grands.
							</li>
						</ul>
					</div>
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-3xl mb-6 text-center">
						<p>
							La chambre des moyens et des grands comprend 15 lits empilables, permettant ainsi l'utilisation de
							l'espace pour d'autres activités dans la journée.
						</p>
						<p>
							L'organisation se fait en quatre temps:{" "}
							<span className="text-brown-300 font-semibold">l'accueil du matin, le repas du midi, la sieste</span> et{" "}
							<span className="text-brown-300 font-semibold">le goûter.</span>
						</p>
						<p>
							Ces temps sont fixes dans la journée, afin que l'enfant puisse se repérer et profiter du temps passé dans
							la structure, en toute tranquilité.
						</p>
					</div>
				</div>
			</section>
			<section className="px-2 py-6">
				<div className="relative overflow-hidden">
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-6 text-center">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Modalités d'inscription
						</h2>
						<div className="text-center">
							<h6 className="mt-2 block text-lg leading-8 tracking-tight text-blue-700 font-bold sm:text-2xl">
								Vous recherchez une place en crèche pour votre enfant?
							</h6>
						</div>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<svg
							className="hidden md:flex absolute top-0 left-full transform -translate-x-1/2 -translate-y-2/4 lg:left-auto  lg:right-0 lg:translate-x-2/4 lg:translate-y-1/4"
							width={404}
							height={784}
							fill="none"
							viewBox="0 0 404 784"
							aria-hidden="true"
						>
							<defs>
								<pattern
									id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
						</svg>
						<h3>
							<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								Voici les grandes étapes de l'inscription en crèche :
							</span>
						</h3>
					</div>
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-3xl mb-6 text-center">
						<p>
							Afin d'obtenir une place, il est indispensable que votre entreprise effectue la réservation d'un berceau.
							Nous pouvons prendre contact directement avec votre service RH ou la direction de votre entreprise.
						</p>
						<p>
							Pour vous inscrire cliquez{" "}
							<a href="/subscribe" className="text-purple-400 font-semibold">
								ici
							</a>
							.
						</p>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<div className="text-center">
							<h3>
								<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
									Vous êtes un salarié d'une entreprise ou d'une administration :
								</span>
							</h3>
						</div>
						<ul>
							<li>
								<p>
									Votre entreprise a effectué la réservation de votre berceau, nous prenons contact avec vous pour un
									rendez-vous avec notre Directrice, qui vous présentera le fonctionnement de la crèche et finalisera
									l'inscription.
								</p>
							</li>
							<li>
								<p>
									Votre entreprise a effectué la réservation de{" "}
									<span className="text-red-400 font-semibold">plusieurs berceaux</span>, l'attribution des places ce
									fait lors d'une <span className="text-blue-400 font-semibold">commission spécifique</span>. Nous
									prendrons contact avec vous à l'issue de cette comission.
								</p>
								<p>
									Pour vous inscrire cliquez{" "}
									<a href="/subscribe" className="text-purple-400 font-semibold">
										ici
									</a>
									.
								</p>
							</li>
						</ul>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<div className="text-center">
							<h3>
								<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
									Vous êtes travailleur non salarié, gérant, libéral... :
								</span>
							</h3>
						</div>
						<ul>
							<li>
								<p>
									Vous pouvez financer votre propre berceau. Contactez-nous au{" "}
									<a href="tel:+33626776075" className="text-purple-400 font-semibold">
										06.26.77.60.75
									</a>{" "}
									pour connaître les modalités d'inscription et les déductions fiscales spécifiques.
								</p>
							</li>
						</ul>
					</div>
					<div className="mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<div className="text-center">
							<h3>
								<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
									Vous êtes habitants de Limeil-Brévannes :
								</span>
							</h3>
						</div>
						<ul>
							<li>
								<p>
									Vous pouvez vous préinscrire{" "}
									<span className="text-blue-400 font-semibold">auprès de la Directrice</span>, pour une place en
									accueil <span className="text-red-500 font-semibold">occasionnelle (type halte-garderie)</span>, qui
									pourra vous être attribuée en fonction des disponibilités.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="px-2 py-6">
				<div className="overflow-hidden">
					<div className="space-y-5 p-2 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl mb-6 text-center">
						<h2 className="mt-2 block text-3xl text-purple-500 text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
							Le coût pour les familles
						</h2>
						<h3>
							<span className="mt-2 block text-xl text-center leading-8 tracking-tight text-brown-400 font-bold sm:text-xl">
								La participation des familles est identique à la tarification appliquée par les crèches collectives
								publique :
							</span>
						</h3>
					</div>
					<div className="relative mt-6 prose prose-indigo prose-md text-gray-700 mx-auto">
						<svg
							className="hidden md:flex absolute top-0 right-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:translate-x-0 lg:translate-y-1/4"
							width={404}
							height={784}
							fill="none"
							viewBox="0 0 404 784"
							aria-hidden="true"
						>
							<defs>
								<pattern
									id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-pink-50" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
						</svg>
						<ul>
							<li>
								<p>
									La participation demandée à la famille couvre la prise en charge de l'enfant pendant son temps de
									présence dans la structure. Les repas (midi+ goûter) ainsi que les couches sont compris dans les
									tarifs et sont donc fournis par la Crèche EfferVsens.
								</p>
							</li>
							<li>
								<p>
									Le calcul du montant des participations de la famille est déterminé par un tarif horaire et s'appuie
									sur un taux d'effort élaboré par la C.N.A.F, modulé en fonctions du nombre d'enfants à charge et des
									ressources de la famille dans la limite d'un plancher et d'un plafond.
								</p>
								<p>Taux d'effort qui varie en fonction du nombre d'enfant à charge:</p>
								<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
									<table className="min-w-full divide-y divide-gray-200">
										<thead className="bg-gray-50">
											<tr>
												<th
													scope="col"
													className="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
												>
													Nombre d'enfant(s)
												</th>
												<th
													scope="col"
													className="px-6 py-3 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
												>
													Taux d'effort horaire
												</th>
											</tr>
										</thead>
										<tbody>
											{children.map((child, childIdx) => (
												<tr key={child.children} className={childIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 text-center">
														{child.children}
													</td>
													<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 text-center">
														{child.percent}%
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</li>
							<li>
								<p>
									Un contrat est établi sur la base d'une présence annuelle de l'enfant: la participation des familles
									est annuelle et forfaitaire. Lorsque l'enfant rentre en cours d'année, elle est calculée au prorata du
									nombre de mois de présence de l'enfant.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Family;
