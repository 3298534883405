import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { SimpleRoute } from "./app/services/Routes";
import Layout from "./shared-components/Layout/Layout";
import routes from "./Routes";

function App() {
	return (
		<BrowserRouter>
			<Layout>
				<Switch>{routes.map(route => SimpleRoute(route))}</Switch>
			</Layout>
		</BrowserRouter>
	);
}

export default App;
